export const PROJECT_LIST = [
    {
      id: "1",
      title: "Test Project - AI Build Planner",
      status: "Live",
      type: "Web App",
      createdAt: "Sept. 13, 2021",
      client: "Myself",
      industry: "AI Development",
      designer: "Myself",
      year: "2021",
    },
    {
      id: "2",
      title: "Smart Home Automation",
      status: "In Progress",
      type: "IoT",
      createdAt: "Nov. 5, 2022",
      client: "Myself",
      industry: "Home Automation",
      designer: "Myself",
      year: "2022",
    },
    {
      id: "3",
      title: "Expense Tracker App",
      status: "Planning",
      type: "Mobile App",
      createdAt: "Jan. 15, 2023",
      client: "Myself",
      industry: "Finance",
      designer: "Myself",
      year: "2023",
    },
    {
      id: "4",
      title: "Raspberry Pi Home Security System",
      status: "Concept",
      type: "IoT",
      createdAt: "Dec. 7, 2022",
      client: "Myself",
      industry: "Home Security",
      designer: "Myself",
      year: "2022",
    },
    {
      id: "5",
      title: "Weather Dashboard",
      status: "Live",
      type: "Web App",
      createdAt: "Aug. 25, 2022",
      client: "Myself",
      industry: "Weather",
      designer: "Myself",
      year: "2022",
    },
    {
      id: "6",
      title: "Health and Fitness Tracker App",
      status: "In Progress",
      type: "Mobile App",
      createdAt: "Oct. 10, 2023",
      client: "Myself",
      industry: "Health",
      designer: "Myself",
      year: "2023",
    },
];